import axios from "axios";
import { createContext, useEffect, useState } from "react";

export const ApiLinkContext = createContext();

export const ApiLinkProvider = (props) => {
  const [products, setProducts] = useState([]);
  const [brand, setBrand] = useState([]);
  const [tag, setTag] = useState([]);
  const [accord, setAccord] = useState([]);

  const [loading, setLoading] = useState(true);
  const [err, setErr] = useState(null);

  const ApiLink = "https://myperfume.az/api";

  const apiName = ["products", "brand", "tag", "accord"];
  useEffect(() => {
    const apiCalls = apiName.map((item) => {
      return axios.get(`https://myperfume.az/api/${item}.php`);
    });
    Promise.all(apiCalls)
      .then(([productRes, brandRes, tagRes, accordRes]) => {
        setProducts(productRes.data);
        setBrand(brandRes.data);
        setTag(tagRes.data);
        setAccord(accordRes.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setErr(err);
      });
  }, []);

  return (
    <ApiLinkContext.Provider
      value={{ products, brand, tag, accord, loading, err, ApiLink }}
    >
      {props.children}
    </ApiLinkContext.Provider>
  );
};

export default ApiLinkContext;
