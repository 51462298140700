import React, { useContext, useEffect, useState } from "react";
import BreadCrumb from "../../utils/BreadCrumb";
import { NavLink } from "react-router-dom";
import slugify from "react-slugify";
import { LanguageContext } from "../../context/LanguageContext";
import translations from "../../data/langData";
import ApiLinkContext from "../../context/ApiLinkContext";
import axios from "axios";
import { Toaster, toast } from "sonner";
const CartView = () => {
  const { ApiLink } = useContext(ApiLinkContext);
  const [localCart, setLocalCart] = useState([]);
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchWishlist = JSON.parse(localStorage.getItem("cart")) || [];
    setLocalCart(fetchWishlist);
  }, []);

  const handleRemoveItem = (index) => {
    const removeItem = localCart.filter((_, idx) => idx !== index);
    setLocalCart(removeItem);
    localStorage.setItem("cart", JSON.stringify(removeItem));
    window.dispatchEvent(new Event("cartUpdated"));
  };

  const [product, setProduct] = useState([]);
  const [brand, setBrand] = useState([]);
  const [accord, setAccord] = useState([]);
  const [tag, setTag] = useState([]);

  useEffect(() => {
    Promise.all([
      axios.get(`${ApiLink}/products.php`),
      axios.get(`${ApiLink}/brand.php`),
      axios.get(`${ApiLink}/tag.php`),
      axios.get(`${ApiLink}/accord.php`),
    ])
      .then(([productsRes, brandsRes, tagsRes, accordsRes]) => {
        const productRes = productsRes.data;
        const brandRes = brandsRes.data;
        const tagRes = tagsRes.data;
        const accordRes = accordsRes.data;
        setTag(tagRes);
        setProduct(productRes);
        setBrand(brandRes);
        setAccord(accordRes);

        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, []);

  const findProduct = product.filter((item) =>
    localCart.some((wishItem) => wishItem.id == item.id)
  );

  const combinedProducts = findProduct.map((prod) => {
    const wishItem = localCart.find((wish) => wish.id === prod.id);
    return {
      ...prod,
      quantity: wishItem ? wishItem.quantity : 0,
    };
  });

  return (
    <>
      <BreadCrumb title={t.cart} />
      {combinedProducts.length > 0 ? (
        <section className="whish-list-section theme1 pt-80 pb-80">
          <div className="container" id="cartTable">
            <div className="row">
              <div className="col-12">
                <h3 className="title mb-30 pb-25 text-capitalize">
                  {t.cartitems}
                </h3>
                <div className="table-responsive">
                  <table className="table">
                    <thead className="thead-light">
                      <tr>
                        <th className="text-center" scope="col">
                          {t.productName}
                        </th>
                        <th className="text-center" scope="col">
                          {t.productName1}
                        </th>
                        <th className="text-center" scope="col">
                          {t.volume}
                        </th>
                        <th className="text-center" scope="col">
                          {t.quantity}
                        </th>
                        <th className="text-center" scope="col">
                          {t.price}
                        </th>
                        <th className="text-center" scope="col">
                          {t.delete}
                        </th>
                        <th className="text-center" scope="col">
                          {t.view}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {combinedProducts.map((i, index) => (
                        <tr key={index}>
                          {i.img && i.img.length > 0 && (
                            <td className="text-center">
                              <img src={i.img[0]} alt="img" />
                            </td>
                          )}
                          <td className="text-center">
                            <span className="whish-title">{i.name}</span>
                          </td>
                          <td className="text-center">
                            <span className="whish-title">
                              {i.volume}
                              {t.ml}
                            </span>
                          </td>
                          <td className="text-center">
                            <p>{i.quantity}</p>
                          </td>
                          <td className="text-center">
                            <span className="whish-list-price">
                              {" "}
                              {i.quantity * i.dis > 0 ? i.dis : i.price} ₼{" "}
                            </span>
                          </td>
                          <td className="text-center">
                            <a
                              style={{ cursor: "pointer" }}
                              onClick={() => handleRemoveItem(index)}
                            >
                              <span className="trash">
                                <i className="fas fa-trash-alt" />{" "}
                              </span>
                            </a>
                          </td>
                          <td className="text-center">
                            <NavLink
                              to={`/${slugify(
                                `${i.id}-${i.name}`
                              ).toLowerCase()}`}
                              className="btn btn-primary btn--lg"
                            >
                              {t.readMore}
                            </NavLink>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="container">
                    <div className="row justify-content-end">
                      <div className="col-2 me-4 ">
                        <NavLink to="/checkout">
                          <div className="d-flex justify-content-center btn btn-primary btn--lg">
                            <p className="fs-6">{t.order.toUpperCase()}</p>
                          </div>
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container" id="wishlistTableMobile">
            <div className="row justify-content-end">
              {combinedProducts.length > 0 &&
                combinedProducts.map((item, index) => (
                  <div className="col-12">
                    <Toaster position="top-right" richColors />
                    <div className="d-flex justify-content-end mt-3">
                      <a
                        style={{ cursor: "pointer" }}
                        onClick={() => handleRemoveItem(index)}
                      >
                        <span className="trash">
                          <i className="fas fa-trash-alt" />
                        </span>
                      </a>
                    </div>
                    <div className="d-flex justify-content-center">
                      {item.img && item.img.length > 0 && (
                        <img
                          src={item.img[0]}
                          alt="img"
                          className="img-fluid"
                          style={{ maxWidth: "70%" }}
                        />
                      )}
                    </div>
                    <div className="d-flex justify-content-between">
                      <h6>{t.productName1}</h6>
                      <h6>{item.name}</h6>
                    </div>
                    <div className="d-flex justify-content-between my-3">
                      <h6>{t.volume}</h6>
                      <h6>
                        {item.volume}
                        {t.ml}
                      </h6>
                    </div>
                    <div className="d-flex justify-content-between mt-3">
                      <h6>{t.quantity}</h6>
                      <h6> x {item.quantity}</h6>
                    </div>
                    <div className="d-flex justify-content-between my-3">
                      <h6>{t.price}</h6>
                      <h6>
                        {item.quantity * item.dis > 0 ? item.dis : item.price} ₼
                      </h6>
                    </div>
                    <div className="d-flex justify-content-end mt-3">
                      <NavLink
                        to={`/${slugify(
                          `${item.id}-${item.name}`
                        ).toLowerCase()}`}
                        className="btn btn-primary btn--lg"
                      >
                        {t.readMore}
                      </NavLink>
                    </div>
                  </div>
                ))}
              <div className="col-4 mt-3 ">
                <NavLink to="/checkout">
                  <div className="d-flex justify-content-center btn btn-primary btn--lg">
                    <p className="fs-6">{t.order.toUpperCase()}</p>
                  </div>
                </NavLink>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <h4 className="text-center py-5">{t.emptycart}</h4>
      )}
    </>
  );
};

export default CartView;
