import React, { useContext, useEffect, useState } from "react";
import BreadCrumb from "../../utils/BreadCrumb";
import ReactImageGallery from "react-image-gallery";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation,Autoplay } from "swiper/modules";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { addItem } from "../../utils/addtocart";
import { addWishlist } from "../../utils/addtowishlist";
import ModalView from "./ModalView";
import ApiLinkContext from "../../context/ApiLinkContext";
import axios from "axios";
import slugify from "react-slugify";

import userlogo from "../../assets/img/user.jpg";
import { LanguageContext } from "../../context/LanguageContext";
import translations from "../../data/langData";

import { Toaster, toast } from "sonner";
import manicon from "../../assets/img/manicon.png";
import womanicon from "../../assets/img/womanicon.jpg";
import unisexicon from "../../assets/img/unisexicon.png";
const ProductDetails = () => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const navigate = useNavigate();
  const [selectVolume, setSelectVolume] = useState(null);

  const [localWish, setLocalWish] = useState(
    () => JSON.parse(localStorage.getItem("wishlist")) || []
  );
  const { productName } = useParams();
  const {products,brand,tag,accord,loading } = useContext(ApiLinkContext);
  const [quantity, setQuantity] = useState(1);



  const [isAdded, setIsAdded] = useState(false);



  const currentData =
    products !== undefined &&
    products.find(
      (item) => slugify(`${item.id}-${item.name}`).toLowerCase() === productName
    );

  const imageArray =
    currentData && Array.isArray(currentData.img) && currentData.img.length > 0
      ? currentData.img.map((image) => ({
          original: image,
          thumbnail: image,
        }))
      : [];

  const [selectedData, setSelectedData] = useState("");

  const increment = () => {
    setQuantity((prevState) => (prevState += 1));
  };

  const decrement = () => {
    if (quantity > 1) {
      setQuantity((prevState) => (prevState -= 1));
    }
  };

  const bName = brand.find((item) => item.id == currentData.brand);
  const aName = accord.filter((i) => currentData.accord.includes(i.id));

  const tName = tag.filter(
    (item) =>
      currentData && currentData.tag && currentData.tag.includes(item.id)
  );
  const cName =
    currentData &&
    (currentData?.sex === "1"
      ? `${t.man}`
      : currentData.sex === "2"
      ? `${t.woman}`
      : currentData.sex === "3"
      ? `${t.unisex}`
      : "Unknown");

  const volumeFilter = products.filter((item) => {
    return item.prod == currentData.prod;
  });
  return (
    <>
      {loading ? (
        <h2 className="text-center my-5">{t.loading}</h2>
      ) : (
        <>
          <BreadCrumb title={`${(currentData && bName) && bName.bName + " " +  currentData.name}`} />
          <Helmet>
            <title>
              {bName && bName.bName} {currentData.name} {currentData.volume} ML
            </title>
          </Helmet>
          <div>
            <Toaster position="top-right" richColors />
            <section className="product-single theme1 pt-60">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6 mb-5 mb-lg-0">
                    <div>
                      <div className="position-relative">
                        <span className="badge top-right" style={{ left: "0" }}>
                          <img
                            src={`${
                              currentData.sex == "1"
                                ? manicon
                                : currentData.sex == "2"
                                ? womanicon
                                : unisexicon
                            }`}
                            alt=""
                            className="img-fluid"
                            style={{
                              position: "absolute",
                              zIndex: "1",
                              right: "0",
                              width: currentData.sex == "1" ? "20px" : "25px",
                            }}
                          />
                        </span>
                        <p
                          style={{ position: "absolute", top: "0", left: "0" }}
                          className={`badge badge-danger mt-2 top-center ${
                            currentData.dis > 0 ? "d-block" : "d-none"
                          }`}
                        >
                          {t.dis}
                        </p>
                      </div>
                      <div className="product-sync-init mb-20">
                        <div className="single-product">
                          <div className="product-thumb">
                            <ReactImageGallery
                              items={imageArray}
                              showNav={false}
                              showPlayButton={false}
                              thumbnailPosition={"bottom"}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="single-product-info">
                      <div className="single-product-head">
                        <h2 className="title mb-20">
                          {" "}
                          {bName && bName.bName} {currentData.name}
                        </h2>
                        <div className="star-content mb-20">
                          <span className="star-on">
                            <i className="ion-ios-star" />{" "}
                          </span>
                          <span className="star-on">
                            <i className="ion-ios-star" />{" "}
                          </span>
                          <span className="star-on">
                            <i className="ion-ios-star" />{" "}
                          </span>
                          <span className="star-on">
                            <i className="ion-ios-star" />{" "}
                          </span>
                          <span className="star-on">
                            <i className="ion-ios-star" />{" "}
                          </span>
                        </div>
                      </div>
                      <div className="product-body mb-40">
                        <div className="d-flex align-items-center mb-30">
                          <p className="product-price">
                            <del
                              className="del fs-4"
                              style={{
                                textDecoration: `${
                                  currentData.dis > 0 ? "line-through" : "none"
                                }`,
                              }}
                            >
                              {currentData.price} ₼
                            </del>
                            <span
                              className={`onsale fs-4 ms-2 ${
                                currentData.dis > 0 ? "" : "d-none"
                              }`}
                            >
                              {currentData.dis} ₼
                            </span>
                          </p>
                          {/* <span className="badge position-static bg-dark rounded-0">
                          Save 10%
                        </span> */}
                        </div>
                        <p>
                          {language === "az"
                            ? currentData.description
                            : language === "en"
                            ? currentData.descriptionEn
                            : currentData.descriptionRu}
                        </p>
                        <br />
                        <div className="container">
                          <div className="row">
                            {volumeFilter.map((item) => {
                              return (
                                <>
                                  <div
                                    className={`col-3 volumeBtn text-center mb-3 ${
                                      productName ==
                                      slugify(`${item.id}-${item.name}`)
                                        ? "activeMl"
                                        : ""
                                    }`}
                                    onClick={() =>
                                      navigate(
                                        `/${slugify(`${item.id}-${item.name}`)}`
                                      )
                                    }
                                  >
                                    {item.volume}
                                    {t.ml}
                                    <br />
                                    <p style={{ fontSize: "small" }}>
                                      {item.dis > 0 ? item.dis : item.price} ₼
                                    </p>
                                  </div>
                                </>
                              );
                            })}
                          </div>
                        </div>
                        <p className="mb-2 ms-2">{t.accord1}</p>
                        <div className="container">
                          <div className="row">
                            {currentData &&
                              currentData.accord &&
                              currentData.accord.map((itemId, index) => {
                                const accordName = accord.find(
                                  (i) => i.id === itemId
                                );
                                const progressWidth = `${currentData.accordP[index]}%`;
                                if (accordName) {
                                  return (
                                    <div className="col-md-12 col-sm-12 col-12">
                                      <div className="row">
                                        <div className="col-2">
                                          <p>
                                            {language === "az"
                                              ? accordName.aName
                                              : language === "en"
                                              ? accordName.aNameEn
                                              : accordName.aNameRu}
                                          </p>
                                        </div>
                                        <div className="col-8">
                                          <div
                                            style={{
                                              width: progressWidth,
                                              background: accordName.aColor,
                                              height: "15px",
                                              borderRadius: "20px",
                                              boxShadow:
                                                "rgba(0, 0, 0, 0.3) 0px 3px 2px 0px",
                                              "--progress-width": progressWidth,
                                            }}
                                            className="my-2 progress-bar"
                                          ></div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                }

                                return null;
                              })}
                          </div>
                        </div>
                      </div>
                      <div className="product-footer">
                        <div className="product-count style d-flex flex-column flex-sm-row mt-30 mb-30">
                          <div className="count d-flex">
                            <input
                              type="number"
                              min={1}
                              max={10}
                              step={1}
                              value={quantity}
                            />
                            <div className="button-group">
                              <button
                                className="count-btn increment"
                                onClick={increment}
                              >
                                <i className="fas fa-chevron-up" />
                              </button>
                              <button
                                className="count-btn decrement"
                                onClick={decrement}
                              >
                                <i className="fas fa-chevron-down" />
                              </button>
                            </div>
                          </div>
                          <div className="d-flex">
                            <button
                              className="btn btn-dark btn--xl mt-5 mt-sm-0"
                              onClick={() => addWishlist(currentData.id)}
                            >
                              <span className="me-2">
                                <i className="icon-heart" />
                              </span>
                              {t.wishlist}
                            </button>

                            <button
                              className={`cart-button ${
                                isAdded ? "clicked" : ""
                              } btn btn-dark btn--xl mt-5 mt-sm-0 ms-3`}
                              onClick={() => {
                                addItem(currentData.id, quantity);
                                setIsAdded(true);
                              }}
                              disabled={`${
                                currentData.say == 0 ? "disabled" : ""
                              }`}
                            >
                              {isAdded ? (
                                <>
                                  <span className="added">{t.addToCart1}</span>
                                </>
                              ) : (
                                <>
                                  {currentData.say == 0 ? (
                                    <span className="add-to-cart" >
                                      {t.say}
                                    </span>
                                  ) : (
                                    <span className="add-to-cart" disabled>
                                      + {t.addToCart}
                                    </span>
                                  )}
                                </>
                              )}
                              <i className="fas fa-shopping-cart"></i>
                              <i className="fas fa-box"></i>
                            </button>
                          </div>
                        </div>
                        {/* <div className="pro-social-links mt-10">
                          <ul className="d-flex align-items-center">
                            <li className="share">Share</li>
                            <li>
                              <a href="#">
                                <i className="ion-social-facebook" />
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i className="ion-social-twitter" />
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i className="ion-social-google" />
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i className="ion-social-pinterest" />
                              </a>
                            </li>
                          </ul>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div className="product-tab theme1 bg-white pt-60 pb-80">
              <div className="container">
                <div className="product-tab-nav">
                  <div className="row align-items-center">
                    <div className="col-12">
                      <nav className="product-tab-menu single-product">
                        <ul
                          className="nav nav-pills justify-content-center"
                          id="pills-tab"
                          role="tablist"
                        >
                          <li className="nav-item">
                            <a
                              className="nav-link active"
                              id="pills-home-tab"
                              data-bs-toggle="pill"
                              href="#pills-home"
                              role="tab"
                              aria-controls="pills-home"
                              aria-selected="true"
                            >
                              {t.desc.toUpperCase()}
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              id="pills-profile-tab"
                              data-bs-toggle="pill"
                              href="#pills-profile"
                              role="tab"
                              aria-controls="pills-profile"
                              aria-selected="false"
                            >
                              {t.pdetails.toUpperCase()}
                            </a>
                          </li>

                          {/* <li class="nav-item">
                            <a
                              class="nav-link"
                              id="pills-contact-tab"
                              data-bs-toggle="pill"
                              href="#pills-contact"
                              role="tab"
                              aria-controls="pills-contact"
                              aria-selected="false"
                            >
                              {t.reviews.toUpperCase()}
                            </a>
                          </li> */}
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="tab-content" id="pills-tabContent">
                      <div
                        className="tab-pane fade show active"
                        id="pills-home"
                        role="tabpanel"
                        aria-labelledby="pills-home-tab"
                      >
                        <div
                          className="single-product-desc"
                          style={{ color: "#000" }}
                        >
                          <p>
                            {language === "az"
                              ? currentData.description
                              : language === "en"
                              ? currentData.descriptionEn
                              : currentData.descriptionRu}
                          </p>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="pills-profile"
                        role="tabpanel"
                        aria-labelledby="pills-profile-tab"
                      >
                        <div className="single-product-desc">
                          <div className="product-anotherinfo-wrapper">
                            <ul>
                              <li>
                                <span>{t.productName1}</span>
                                {currentData.name}
                              </li>
                              <hr />
                              <li>
                                <span>{t.brand}</span>
                                {bName.bName}
                              </li>
                              <hr />
                              <li className="d-flex flex-wrap">
                                <span>{t.accord1}</span>{" "}
                                {currentData &&
                                  currentData.accord &&
                                  currentData.accord.map((item, index) => {
                                    const accordName = accord.find(
                                      (i) => i.id === item
                                    );
                                    if (accordName) {
                                      return (
                                        <>
                                          <li>
                                            <NavLink
                                              style={{
                                                textDecoration: "underline",
                                              }}
                                              className="navlinkAccord"
                                              to={`/accord/${slugify(
                                                `${accordName.id}-${
                                                  language === "az"
                                                    ? accordName.aName
                                                    : language === "en"
                                                    ? accordName.aNameEn
                                                    : accordName.aNameRu
                                                }`
                                              )}`}
                                            >
                                              {language === "az"
                                                ? accordName.aName
                                                : language === "en"
                                                ? accordName.aNameEn
                                                : accordName.aNameRu}
                                            </NavLink>
                                            {index < aName.length - 1 && " , "}{" "}
                                          </li>
                                        </>
                                      );
                                    }
                                  })}
                              </li>
                              <hr />
                              <li className="d-flex flex-wrap">
                                <span>{t.not}</span>{" "}
                                {currentData &&
                                  currentData.tag &&
                                  currentData.tag.map((item, index) => {
                                    const tagName = tag.find(
                                      (i) => i.id === item
                                    );
                                    if (tagName) {
                                      return (
                                        <>
                                          <li>
                                            <NavLink
                                              style={{
                                                textDecoration: "underline",
                                              }}
                                              className="navlinkAccord"
                                              to={`/tag/${slugify(
                                                `${tagName.id}-${
                                                  language === "az"
                                                    ? tagName.tName
                                                    : language === "en"
                                                    ? tagName.tNameEn
                                                    : tagName.tNameRu
                                                }`
                                              )}`}
                                            >
                                              {language === "az"
                                                ? tagName.tName
                                                : language === "en"
                                                ? tagName.tNameEn
                                                : tagName.tNameRu}
                                            </NavLink>
                                            {index < tName.length - 1 && " , "}{" "}
                                          </li>
                                        </>
                                      );
                                    }
                                  })}
                              </li>
                              <hr />
                              <li>
                                <span>{t.category}</span> {cName}
                              </li>
                              <hr />
                              <li>
                                <span>{t.volume}</span> {currentData.volume}{" "}
                                {t.ml}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div
                        class="tab-pane fade show "
                        id="pills-contact"
                        role="tabpanel"
                        aria-labelledby="pills-contact-tab"
                      >
                        <div class="single-product-desc">
                          <div class="row">
                            <div class="col-lg-7">
                              <div class="review-wrapper">
                                {[0, 1].map((item) => (
                                  <div class="single-review">
                                    <div class="review-img">
                                      <img src={userlogo} alt="" />
                                    </div>
                                    <div class="review-content">
                                      <div class="review-top-wrap">
                                        <div class="review-left">
                                          <div class="review-name">
                                            <h4>Müştəri</h4>
                                          </div>
                                          <div class="rating-product">
                                            <i class="ion-android-star"></i>
                                            <i class="ion-android-star"></i>
                                            <i class="ion-android-star"></i>
                                            <i class="ion-android-star"></i>
                                            <i class="ion-android-star"></i>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="review-bottom">
                                        <p>Müştəri Rəyi</p>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                            <div class="col-lg-5">
                              <div class="ratting-form-wrapper">
                                <h3>Add a Review</h3>
                                <div class="ratting-form">
                                  <form action="#">
                                    <div class="star-box">
                                      <span>Your rating:</span>
                                      <div class="rating-product">
                                        <i class="ion-android-star"></i>
                                        <i class="ion-android-star"></i>
                                        <i class="ion-android-star"></i>
                                        <i class="ion-android-star"></i>
                                        <i class="ion-android-star"></i>
                                      </div>
                                    </div>
                                    <div class="row">
                                      <div class="col-md-6">
                                        <div class="rating-form-style mb-10">
                                          <input
                                            placeholder="Name"
                                            type="text"
                                          />
                                        </div>
                                      </div>
                                      <div class="col-md-6">
                                        <div class="rating-form-style mb-10">
                                          <input
                                            placeholder="Email"
                                            type="email"
                                          />
                                        </div>
                                      </div>
                                      <div class="col-md-12">
                                        <div class="rating-form-style form-submit">
                                          <textarea
                                            name="Your Review"
                                            placeholder="Message"
                                          ></textarea>
                                          <input type="submit" value="Submit" />
                                        </div>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <section className="theme1 bg-white pb-80">
              <div className="container">
                <div className="row ">
                  <div className="col-12">
                    <div className="section-title text-center">
                      <h2 className="title pb-3 mb-3">{t.product}</h2>
                    </div>
                  </div>
                  <Swiper
                    slidesPerView={1}
                    spaceBetween={3}
                    navigation={true}
                    loop={true}
                    autoplay={{
                      delay: 1800,
                      disableOnInteraction: false, 
                    }}
                    breakpoints={{
                      640: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                      },
                      768: {
                        slidesPerView: 4,
                        spaceBetween: 40,
                      },
                      1024: {
                        slidesPerView: 4,
                        spaceBetween: 3,
                      },
                    }}
                    modules={[Navigation,Autoplay]}
                    className="mySwiper"
                  >
                    {products.map((i) => {
                      const active = localWish.find((item) => item.id == i.id);
                      const allBName = brand.find((item)=>item.id == i.brand)
                      return (
                        <SwiperSlide>
                          <div className="col-lg-12 col-md-12 col-sm-6 col-12 mb-5">
                            <div className="product-slider-init theme1 slick-nav">
                              <div className="slider-item mx-2  ">
                                <div className="card product-card">
                                  <div className="card-body p-0">
                                    <div className="media flex-column">
                                      <div className="product-thumbnail position-relative">
                                        <span
                                          className="badge top-right"
                                          style={{ left: "0" }}
                                        >
                                          <img
                                            src={`${
                                              i.sex == "1"
                                                ? manicon
                                                : i.sex == "2"
                                                ? womanicon
                                                : unisexicon
                                            }`}
                                            alt=""
                                            className="img-fluid"
                                            style={{
                                              position: "absolute",
                                              zIndex: "1",
                                              right: "0",
                                              width:
                                                i.sex == "1" ? "20px" : "25px",
                                            }}
                                          />
                                        </span>
                                        <p
                                          style={{
                                            position: "absolute",
                                            top: "0",
                                            left: "0",
                                          }}
                                          className={`badge badge-danger mt-2 ms-2  top-center ${
                                            i.dis > 0 ? "d-block" : "d-none"
                                          }`}
                                        >
                                          {t.dis}
                                        </p>
                                        <NavLink
                                          to={`/${slugify(
                                            `${i.id}-${i.name}`
                                          ).toLowerCase()}`}
                                        >
                                          {i.img &&
                                            Array.isArray(i.img) &&
                                            i.img.length > 0 && (
                                              <>
                                                <img
                                                  className="first-img"
                                                  src={i.img[1]}
                                                  alt="thumbnail"
                                                />
                                                <img
                                                  className="second-img"
                                                  src={i.img[0]}
                                                  alt="thumbnail"
                                                />
                                              </>
                                            )}
                                        </NavLink>

                                        <ul className="actions d-flex justify-content-center">
                                          <li>
                                            <a
                                              className={`action ${
                                                active ? "activeWish" : ""
                                              }`}
                                              onClick={() => addWishlist(i.id)}
                                              style={{ cursor: "pointer" }}
                                            >
                                              <span
                                                data-bs-toggle="tooltip"
                                                data-placement="bottom"
                                                title="add to wishlist"
                                                className="icon-heart"
                                              ></span>
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              className="action"
                                              href="#"
                                              data-bs-toggle="modal"
                                              data-bs-target="#quick-view"
                                              onClick={() => {
                                                setSelectedData(i);
                                              }}
                                            >
                                              <span
                                                data-bs-toggle="tooltip"
                                                data-placement="bottom"
                                                title="Quick view"
                                                className="icon-magnifier"
                                              />
                                            </a>
                                          </li>
                                        </ul>
                                      </div>
                                      <div className="media-body">
                                        <div className="product-desc">
                                          <h3 className="title">
                                            <a className="text-capitalize">
                                           {allBName && allBName.bName}   {i.name} {i.volume}
                                              {t.ml}
                                            </a>
                                          </h3>
                                          <div className="star-rating">
                                            <span className="ion-ios-star" />
                                            <span className="ion-ios-star" />
                                            <span className="ion-ios-star" />
                                            <span className="ion-ios-star" />
                                            <span className="ion-ios-star de-selected" />
                                          </div>
                                          <div className="d-flex align-items-center justify-content-between">
                                            <p className="product-price">
                                              <del
                                                className="del "
                                                style={{
                                                  textDecoration: `${
                                                    i.dis > 0
                                                      ? "line-through"
                                                      : "none"
                                                  }`,
                                                }}
                                              >
                                                {i.price} ₼
                                              </del>
                                              <span
                                                className={`onsale  ms-2 ${
                                                  i.dis > 0 ? "" : "d-none"
                                                }`}
                                              >
                                                {i.dis} ₼
                                              </span>
                                            </p>
                                            <button
                                              className="pro-btn "
                                              onClick={() => {
                                                addItem(i.id);
                                                toast.success(
                                                  `${t.cart + ":"} ${i.name}-${
                                                    i.volume + "ML"
                                                  }`,
                                                  {
                                                    duration: 700,
                                                    style: {
                                                      backgroundColor:
                                                        "#00C2CB",
                                                      color: "#ffffff",
                                                      fontFamily: "Montserrat",
                                                      border: "none",
                                                    },
                                                  }
                                                );
                                              }}
                                            >
                                              <i className="icon-basket" />
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                </div>
              </div>
            </section>
          </div>
          <ModalView data={selectedData} />
        </>
      )}
    </>
  );
};

export default ProductDetails;
