import React, { useContext, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { EffectFade, Navigation, Autoplay } from "swiper/modules";
import { NavLink, useNavigate } from "react-router-dom";
import { LanguageContext } from "../../context/LanguageContext";
import translations from "../../data/langData";
import { ApiLinkContext } from "../../context/ApiLinkContext";
import axios from "axios";
import homebanner from "../../assets/img/homebanner.webp";

const HomeSlider = () => {
  const { products,brand,tag,accord } = useContext(ApiLinkContext);
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const [query, setQuery] = useState("");
  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();
    const result = products.filter((item) =>
      item.name.toLowerCase().includes(query.toLowerCase())
    );
    const brandResult = brand.filter((item) =>
      item.bName.toLowerCase().includes(query.toLowerCase())
    );

    const accordResult = accord.filter((item) =>
      item.aName.toLowerCase().includes(query.toLowerCase())
    );

    const tagResult = tag.filter((item) =>
      item.tName.toLowerCase().includes(query.toLowerCase())
    );

   const data = 
    result.length > 0 ? { type: "product", items: result } : 
    brandResult.length > 0 ? { type: "brand", items: brandResult } : 
    accordResult.length > 0 ? { type: "accord", items: accordResult } : 
    tagResult.length > 0 ? { type: "tag", items: tagResult } : 
    null;
    navigate("/perfume", {
      state: { data, query },
    });
  };

  return (
    <>
      <Swiper
        spaceBetween={30}
        effect={"fade"}
        navigation={false}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        modules={[EffectFade, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide>
          <div className="slider_container">
            <div className="slider_text">
              <form
                action=""
                className="formSearch d-flex align-items-end"
                onSubmit={handleSubmit}
              >
                <input
                  type="text"
                  name=""
                  className="searchInput"
                  placeholder={t.search}
                  onChange={(e) => setQuery(e.target.value)}
                  value={query}
                />
                <button
                  className="text-white ms-2 d-none"
                  type="submit"
                  style={{
                    width: "auto",
                    height: "35px",
                    padding: "5px 8px",
                    border: "1px solid #f9f9f9",
                    borderRadius: "5px",
                  }}
                >
                  Axtar
                </button>
              </form>
            </div>

            <img src={homebanner} className="img-fluid homeBanner" />
            <div className="outline"></div>
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
};

export default HomeSlider;
